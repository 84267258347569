import $ from 'jquery'

const functions = {
  initHeaderEvents: function() {
    $('.header').on('meganav-open', () => {
      console.log('meganav open');
      $('.header').addClass('u-remove-border');
    });
    $('.header').on('meganav-close', () => {
      $('.header').removeClass('u-remove-border');
    });
    $('.js-hamburger--mobile').on('click', () => {
      $('.header').toggleClass('u-bg-white');
    });
    $('.js-hamburger').on('click', () => {
      $('.header').addClass('u-bg-white');
    })
  },
  headerWiderWindowHandler : function(document) {
    if ($(window).width() > 1180) {
      document.on('click', '.js-hamburger', () => {
        $('.header').trigger('meganav-open');
        $('.header__collapsed--desktop').css('display', 'none');
        $('.header--desktop-wrapper').css({ "display" : "block", "opacity" : 1, 'padding-bottom' : '25px' });
      });
      document.on('scroll', () => {
        $('.header__collapsed--desktop').css("display", "block");
        $('.header--desktop-wrapper').css("display" , "none");
        $('.header').trigger('meganav-close');
        $('.header').removeClass('u-bg-white');
      });
    }
  },
  scrollIntoViewHandler: function(headroomInstance) {
    $('.sticky-sidenav a[href^="#"]:not(.button)').on('click', (e) => {
      e.preventDefault();
      let thisClickedElValue = $(e.currentTarget).attr('href');
      thisClickedElValue = thisClickedElValue.replace(/^#+/i, '');
      let allScrollAnchorEls = $('a.u-anchor-link');
      let scrollIntoViewAnchorEl = $(`a.u-anchor-link[name="${thisClickedElValue}"]`);

      var rect = document.querySelector(`a.u-anchor-link[name="${thisClickedElValue}"]`).getBoundingClientRect();

      // If anything else is lower than 170 it mean that page will scroll up.
      if (rect.top < 170) {
        allScrollAnchorEls.removeClass('u-anchor-link--will-scroll-down u-anchor-link--will-scroll-up');
      } else {
        allScrollAnchorEls.removeClass('u-anchor-link--will-scroll-up u-anchor-link--will-scroll-down');
        scrollIntoViewAnchorEl.addClass('u-anchor-link--will-scroll-down');
      }

      setTimeout(() => {
        document.querySelector(`a.u-anchor-link[name="${thisClickedElValue}"]`).scrollIntoView();
      }, 250);
    })
  }
}

export default functions;
