import $ from 'jquery';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import Headroom from 'headroom.js';
import eventFunctions from './events/functions';

const Mangrove = {
  init() {
    this.cache();
    this.events();
    this.initHeadroom();
    this.toggleMobileSubmenu();
    this.initNumberCountUp();
    this.initSliders();
    this.triggerModalOpen();
    if(this.$body.hasClass('home')) this.initHomepageBanner();
  },
  cache() {
    this.$doc = $(document);
    this.$win = $(window);
    this.$html = $('html');
    this.$body = $('body');
    this.$mainWrap = $('main');
    this.$header = $('.js-header');
    this.$headerMegaHeight = this.$header.find('.header--desktop-wrapper').height();
    this.$headerCollapsedHeight = this.$header.find('.header__collapsed--desktop').height();
    this.$mobileNav = $('.js-mobile-nav');
    this.$hamburger = $('.js-hamburger');
  },
  events() {
    this.$doc.on('click', '.js-hamburger--mobile', (event) => {
      this.showMobileNav(event)
    });
    this.$doc.on('click', '.js-share', event => this.openShareWindow(event));
    this.$doc.on('click', '.js-accordion-item', event => this.openAccordion(event));
    this.$doc.on('click', '.js-team-expand', event => this.teamExpand(event));
    this.$doc.on('click', '.js-close-banner', event => this.closeHomeBanner(event));
    // this.$doc.on('click', '.js-lang-picker a', event => this.langGooglePicker(event));
    eventFunctions.headerWiderWindowHandler(this.$doc);
    eventFunctions.initHeaderEvents();
  },
  openShareWindow(event) {
    let link = $(event.currentTarget).attr('href');
    if (link.substr(0, 6) != 'mailto') {
      event.preventDefault();
      window.open(link, '', 'width=600, height=400');
    }
  },
  initHeadroom() {
    let theHeaderHome = document.querySelector('.js-header--home');
    let theHeader = document.querySelector('.js-header');

    let headroomHome = new Headroom(theHeaderHome, {
      offset: 0,
      onTop : function() {
        $('.header__collapsed--desktop').addClass('u-hide');
        $('.header--desktop-wrapper').css({
          "opacity" : 1,
          "position" : 'relative',
          'display' : 'block'
        });
      },
      onNotTop: function() {
        $('.header--desktop-wrapper').css('display', 'none');
        $('.header__collapsed--desktop').removeClass('u-hide');
      }
    });
    if ($('body').hasClass('home')) {
      headroomHome.init();
    } else {
      let headRoom = new Headroom(theHeader, {
        offset: 0,
        onNotTop: function() {

        },
        onTop: function() {
          $('.sticky-sidenav').css('top', '0px');
        },
        onUnpin : function() {
          $('.sticky-sidenav').css('top', '20px');
        },
        onPin : function() {
          $('.sticky-sidenav').css('top', '170px');
        }
      });
      headRoom.init();
      eventFunctions.scrollIntoViewHandler(headRoom);
    }
  },
  showMobileNav(event) {
    console.log('this func is triggered');
    event.preventDefault();
    this.$mobileNav.toggleClass('is-active');
    this.$hamburger.toggleClass('is-active');
    $('.header__mobile-menu').toggle();
    $('.header').toggleClass('mobile-drawer-open');
    $('body, html').toggleClass('u-prevent-scroll');

    if (this.$mobileNav.hasClass('is-active')) {
      this.$hamburger.attr('aria-pressed', 'true');
    } else {
      this.$hamburger.removeAttr('aria-pressed');
    }
  },
  toggleMobileSubmenu() {
    let mobileMenu = $('.header__mobile-menu');

    mobileMenu.find('.has-submenu').each((idx, el) => {
      $(el).find('> a').on('click', () => {
        $(el).find('.mobile-submenu').toggle();
      });
    });
  },
  initNumberCountUp() {

    let el = document.querySelector('.stats__box');
    let isInViewportFirst = false;
    const countUpObj = [];

    import('./helper/isInViewport').then( obj => {

      const isInViewport = obj.default;

      if(typeof(el) != 'undefined' && el != null){

        document.addEventListener('scroll', () => {
          let el = document.querySelector('.page-contents .stats--wrapper');

          if (isInViewport(el) && !isInViewportFirst) {

            import('countup.js').then( obj => {

              const CountUp = obj.CountUp;

              $('.page-contents .stats--wrapper').find('.stats__number').each((idx, el) => {
                let number  = parseInt($(el).attr('data-number'));
                let el_id = $(el).attr('id');
                countUpObj[idx] = new CountUp(el_id, number, {duration : 3});
              });

              isInViewportFirst = true;

              countUpObj.forEach(stats_number => {
                stats_number.start();
              });

            }).catch( err => {
              console.log('Error while loading import object dynamically', err);
            })

          }
        });
      }
    }).catch( err => {
      console.log('Something went wrong with importing the isInViewport module', err);
    });
  },
  initSliders() {

    import('slick-carousel').then( obj => {

      if($('.js-posts-slider').length > 0) {
        $('.js-posts-slider').each(function(idx, el) {
          let itemNum = $(el).data('colnumber');
          $(el).slick({
            dots: false,
            arrows: true,
            slidesToShow: itemNum,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            infinite: false,
            responsive: [
              {
                breakpoint: 960,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 700,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
          });
        });
      }

      if($('.js-featured-slider').length > 0) {
        $('.js-featured-slider').each(function(idx, el) {
          $(el).slick({
            dots: false,
            arrows: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            infinite: false,
            responsive: [
              {
                breakpoint: 960,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 700,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
          });
        });
      }

      if($('.js-timeline-slider').length > 0) {

        $('.js-timeline-slider').one('lazyloaded', () => {

          $('.js-timeline-slider').each(function(idx, el) {
            $(el).slick({
              dots: false,
              arrows: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: false,
              autoplaySpeed: 5000,
              infinite: false,
              adaptiveHeight: true
            });
          });
        });
      }

    }).catch( err => {
      console.log('Error while Importing Slick', err);
    })


  },
  openAccordion(event) {
    event.preventDefault();
    var accItem = $(event.currentTarget).parent('.accordion__item');
    accItem.toggleClass('is-active');
  },
  triggerModalOpen() {

    import('micromodal').then( obj => {
      const MicroModal = obj.default;
      $('a').each((idx,el) => {
        if($(el).attr('href') == '#submit-for-review') {
          $(el).on('click', () => {
            MicroModal.show('modal-submit-post-for-review-form', {
              disableScroll: true, // [6]
            });
          })
        }
        if($(el).attr('href') == '#get-in-touch') {
          $(el).on('click', () => {
            MicroModal.show('modal-get-in-touch', {
              disableScroll: true, // [6]
              onShow: () => {
                $('.js-header').css('z-index', '-1');
                $('.js-header--home').css('z-index', '-1');
                $('.sticky-sidenav').hide();
              },
              onClose: () => {
                $('.js-header').css('z-index', '1000');
                $('.js-header--home').css('z-index', '1000');
                $('.sticky-sidenav').show();
              }
            });
          })
        }
      })
    }).catch( err => console.log(err, 'Error!'));


  },
  teamExpand(event) {
    event.preventDefault();
    var el = $(event.currentTarget);
    el.toggleClass('is-active');
    el.siblings('.complete').toggleClass('is-active');
  },
  initHomepageBanner() {
    if ($('.home-banner').length){
      if (!(sessionStorage.getItem('set') === 'set')) {
        $('.home-banner').removeClass('closed');
        setTimeout(function(){ 
          var bannerHeight = $('.home-banner').innerHeight(),
              mainPaddingMobile = 110 + bannerHeight,
              mainPaddingDesktop = 316 + bannerHeight;

              // console.log(bannerHeight);
              // console.log(mainPaddingMobile);
              // console.log(mainPaddingDesktop);
   
          if($(window).width() < 1181) {
            $('.main').css('padding-top', mainPaddingMobile + 'px');
          } else {
            $('.main').css('padding-top', mainPaddingDesktop + 'px');
          }
        }, 1000);
      } 
    }

    //Fade in home page top section when all images are loaded
    // var images = $('.building-block--home-first-section img');
    // var loadedImagesCount = 0;

    // images.each(function(){
    //   console.log('loaded');
    //   $(this).load(function(){
    //     loadedImagesCount++;

    //     console.log('loaded count: ' + loadedImagesCount);
    //     console.log('total count: ' + images.length);

    //   });
    //   if (loadedImagesCount === images.length) {
    //     $('.building-block--home-first-section').addClass('is-visible');
    //   }
    // });
  },
  closeHomeBanner(event) {
    event.preventDefault();
    var el = $(event.currentTarget);
    el.parent().addClass('closed');
    sessionStorage.setItem("set", "set");
    if($(window).width() < 1181) {
      $('.main').css('padding-top', '110px');
    } else {
      $('.main').css('padding-top', '316px');
    }
  },
};

Mangrove.init();
